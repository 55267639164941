import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { ProductDocument, Variation } from 'models/product';
import { Fragment, useEffect } from 'react';
import { MakeAnOfferForm } from '../forms/MakeAnOfferForm';
import { XMarkIcon } from '@heroicons/react/20/solid';

type MobileMakeAnOfferModalProps = {
  show: boolean;
  dismiss: () => void;
  product: ProductDocument;
  selectedVariations: Variation[] | null;
};

export default function MobileMakeAnOfferModal({
  show,
  dismiss,
  product,
  selectedVariations,
}: MobileMakeAnOfferModalProps) {
  // useEffect to lock body scroll when modal is open
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [show]);

  return (
    <>
      <Transition show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hidden"
          onClose={dismiss}
        >
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-x-0 bottom-0 flex w-full">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <DialogPanel className="relative h-[90rem] w-full ">
                  <div
                    className={`flex h-full flex-col rounded-t-[4rem] bg-white px-4 py-6 shadow-xl `}
                  >
                    <div className="relative flex w-full items-center justify-between border-b border-[#F1F1F1]">
                      <span className="my-4 flex-1 text-center text-[2.1rem] font-bold text-black">
                        Make an Offer
                      </span>

                      <div className="absolute right-0 top-4">
                        <button
                          type="button"
                          onClick={() => dismiss()}
                          className="mr-4 text-black"
                        >
                          <XMarkIcon className="h-12 w-12" />
                        </button>
                      </div>
                    </div>
                    <MakeAnOfferForm
                      product={product}
                      selectedVariations={selectedVariations}
                      dismiss={dismiss}
                    />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
