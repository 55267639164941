import { FedExIcon, MxShipping, USPSIcon } from '@c/icons';
import { UPS } from '@c/icons/carriers';
import { PencilIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { AddressDocument } from '@models/address';
import { ProductDocument } from '@models/product';
import SafeImage from '@ui/SafeImage';
import { Rate } from '@util/firestore/shipengine';
import { useRates } from '@util/hooks/useRates';
import { capitalize, formatCurrency, isMobile } from '@util/index';
import { useEffect, useRef } from 'react';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@c/sheet';
import { usePathname } from 'next/navigation';

function ItemShippingSelect({
  product,
  shipment,
  shipTo,
  hideProduct = false,
  chooseCheapestByDefault = false,
  hide = false,
  onOptionSelected,
  selectedRateId,
  offer_amount,
  customsInfo,
  onError,
  onRatesLoaded,
}: {
  product?: ProductDocument;
  shipment?: ProductDocument['shipment'];
  shipTo: AddressDocument;
  hideProduct?: boolean;
  chooseCheapestByDefault?: boolean;
  hide?: boolean;
  onOptionSelected?: ({
    rate,
    product_id,
    seller_id,
  }: {
    rate: Rate;
    product_id: string;
    seller_id: string;
  }) => void;
  selectedRateId: string | null;
  offer_amount?: number;
  customsInfo?: {
    product_title: string;
    product_category: string;
    product_sku: string;
    product_price: number;
  };
  onError?: (error: Error) => void;
  onRatesLoaded?: () => void;
}) {
  const { rates, ratesError, ratesLoading, selectOption, getFlatRateShipping } =
    useRates({
      product,
      shipment,
      shipTo,
      chooseCheapestByDefault,
      offer_amount,
      customsInfo,
      onOptionSelected,
      selectedRateId,
    });

  const pathname = usePathname();
  const closeRef = useRef<any>(null);

  useEffect(() => {
    if (ratesError) {
      onError?.(ratesError as Error);
    }
  }, [ratesError, onError]);

  useEffect(() => {
    if (rates?.length && !ratesLoading) {
      onRatesLoaded?.();
    }
  }, [rates, ratesLoading, onRatesLoaded]);

  if (hide) return null;

  const isCheckout = pathname?.includes('checkout');

  const handleOptionSelect = (option: Rate) => {
    selectOption(option);

    onOptionSelected?.({
      rate: option,
      product_id: product?.id ?? '',
      seller_id: product?.seller_id ?? '',
    });
  };

  const flatRate = getFlatRateShipping();

  const selectedRate = rates?.find((r) => r.rate_id === selectedRateId);
  const delivery_days =
    selectedRate?.delivery_days ??
    (rates?.find((r) => r.service_code === 'ups_ground')?.delivery_days ?? 5) +
      1;

  return (
    <div className="h-full">
      {product && !hideProduct && (
        <div className={`my-4 flex items-center gap-x-4`}>
          <SafeImage
            alt={product.title}
            src={product.thumbnail}
            height={100}
            width={100}
            className="aspect-square h-32 w-32 rounded-2xl object-cover"
          />

          <div className={`flex w-full items-start justify-between`}>
            <div className="flex flex-col gap-y-2">
              <h5 className="line-clamp-1 w-[25rem] whitespace-pre-wrap text-[1.6rem] font-semibold sm:w-[28rem]">
                {product.title}
              </h5>

              <div className="flex items-center gap-x-2">
                <span className="text-elipsis line-clamp-1 text-[1.6rem] font-medium text-brand-dark-gray sm:pr-10">
                  {product.category1 === 'Boots' ||
                  product.category1 === 'Helmets'
                    ? product.category1
                    : product.category2
                    ? product.category2
                    : product.location_display
                    ? product.location_display
                    : product.category1
                    ? product.category1
                    : product.category}
                  {product.size && (
                    <>
                      {product.size?.letter
                        ? `, Size: ${product.size?.letter}`
                        : ''}
                      {product.size?.number
                        ? `${product.size?.letter ? '/ ' : ''} Size: ${
                            product.size?.number
                          }`
                        : ''}
                    </>
                  )}
                </span>
              </div>
            </div>

            <div className="flex flex-col items-end gap-y-2">
              <p className="text-[1.6rem] font-semibold">
                {formatCurrency(product.price)}
              </p>

              <p className="text-[1.6rem] text-zinc-500">
                Qty: {product.quantity ?? 1}
              </p>
            </div>
          </div>
        </div>
      )}
      {product?.is_flat_rate && flatRate && isCheckout && (
        <div className="flex items-center justify-between gap-x-4 rounded-[2rem] bg-gray-100 p-6">
          <div className="flex items-center gap-[1.6rem]">
            <div className="w-[5rem] shrink-0">
              <MxShipping width={29} height={21} />
            </div>
            <div className="flex flex-col">
              <p className="text-[1.6rem] font-semibold text-black">
                Flat Rate
              </p>
              <p className="text-[1.4rem] text-brand-gray">
                Shipping carrier selected by seller
              </p>
            </div>
          </div>

          <div className="text-[1.6rem] font-semibold">{flatRate}</div>
        </div>
      )}
      {(!isCheckout || !product?.is_flat_rate) && (
        <Sheet>
          {!ratesLoading && (
            <SheetTrigger
              className={`flex w-full gap-[1.6rem] rounded-[2rem]  bg-gray-100 p-6 `}
              type="button"
            >
              {selectedRate ? (
                <>
                  <div className="w-[5rem] shrink-0">
                    {selectedRate.carrier_code === 'fedex' ? (
                      <FedExIcon className="h-16 w-16" />
                    ) : selectedRate.carrier_code === 'ups' ? (
                      <UPS className="h-16 w-16" />
                    ) : (
                      <USPSIcon className="h-16 w-16" />
                    )}
                  </div>

                  <div className="flex grow items-center gap-5">
                    <div className="flex flex-col items-start  gap-y-2">
                      <p className="text-[1.6rem] font-semibold text-black">
                        {delivery_days} {delivery_days === 1 ? 'Day' : 'Days'}
                      </p>

                      <p className="text-left text-[1.4rem] text-brand-gray">
                        {selectedRate.service_type}
                      </p>
                      {selectedRate.package_type &&
                        selectedRate.package_type !== 'package' && (
                          <p className="text-[1.4rem] text-brand-gray">
                            {selectedRate.package_type
                              .split('_')
                              .map(capitalize)
                              .join(' ')}
                          </p>
                        )}
                    </div>

                    <div className="ml-auto text-[1.6rem] font-semibold text-black">
                      {formatCurrency(selectedRate.total_amount)}
                    </div>
                    <PencilIcon width={20} height={20} />
                  </div>
                </>
              ) : (
                <div className="flex w-full items-center justify-between gap-y-2">
                  <p className="text-[1.75rem] font-semibold">
                    Select Shipping
                  </p>
                  <PlusCircleIcon className="h-16 w-16 text-brand-secondary" />
                </div>
              )}
            </SheetTrigger>
          )}

          <SheetContent
            side={isMobile() ? 'bottom' : 'right'}
            className="flex h-full max-h-[75%] flex-col rounded-3xl bg-white text-black sm:max-h-none sm:min-w-[50rem] sm:rounded-none"
          >
            <SheetHeader>
              <SheetTitle>Select Shipping Rate</SheetTitle>
            </SheetHeader>
            {product && !hideProduct && (
              <div className={`my-4 flex items-center gap-x-4`}>
                <SafeImage
                  alt={product.title}
                  src={product.thumbnail}
                  height={100}
                  width={100}
                  className="aspect-square h-32 w-32 rounded-2xl object-cover"
                />

                <div className={`flex w-full items-start justify-between`}>
                  <div className="flex flex-col gap-y-2">
                    <h5 className="line-clamp-1 w-[25rem] whitespace-pre-wrap text-[1.6rem] font-semibold sm:w-[28rem]">
                      {product.title}
                    </h5>

                    <div className="flex items-center gap-x-2">
                      <span className="text-elipsis line-clamp-1 text-[1.6rem] font-medium text-brand-dark-gray sm:pr-10">
                        {product.category1 === 'Boots' ||
                        product.category1 === 'Helmets'
                          ? product.category1
                          : product.category2
                          ? product.category2
                          : product.location_display
                          ? product.location_display
                          : product.category1
                          ? product.category1
                          : product.category}
                        {product.size && (
                          <>
                            {product.size?.letter
                              ? `, Size: ${product.size?.letter}`
                              : ''}
                            {product.size?.number
                              ? `${product.size?.letter ? '/ ' : ''} Size: ${
                                  product.size?.number
                                }`
                              : ''}
                            {!!product.variations?.length &&
                            product.variations.length > 1
                              ? ', Size: Multiple Sizes'
                              : ''}
                          </>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col items-end gap-y-2">
                    <p className="text-[1.6rem] font-semibold">
                      {formatCurrency(product.price)}
                    </p>

                    <p className="text-[1.6rem] text-zinc-500">
                      Qty: {product.quantity ?? 1}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className={`flex flex-col gap-y-4 overflow-y-scroll`}>
              {!!rates?.length &&
                rates.map((option) => {
                  const delivery_days =
                    option.delivery_days ??
                    (rates.find((r) => r.service_code === 'ups_ground')
                      ?.delivery_days ?? 5) + 1;

                  return (
                    <button
                      className={`flex w-full gap-[1.6rem] rounded-[2rem] p-6 ${'bg-[#F6F6F6]'}`}
                      type="button"
                      onClick={() => {
                        handleOptionSelect(option);
                        closeRef.current?.click();
                      }}
                      key={option.rate_id}
                    >
                      <div className="w-[6rem]">
                        {option.carrier_code === 'fedex' ? (
                          <FedExIcon className="h-16 w-16" />
                        ) : option.carrier_code === 'ups' ? (
                          <UPS className="h-16 w-16" />
                        ) : (
                          <USPSIcon className="h-16 w-16" />
                        )}
                      </div>

                      <div className="flex grow items-center gap-5">
                        <div className="flex flex-col items-start">
                          <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                            {delivery_days}{' '}
                            {delivery_days === 1 ? 'Day' : 'Days'}
                          </h5>

                          <p className="text-[1.4rem] text-brand-gray">
                            {option.service_type}
                          </p>
                          {option.package_type &&
                            option.package_type !== 'package' && (
                              <p className="text-[1.4rem] text-brand-gray">
                                {option.package_type
                                  .split('_')
                                  .map(capitalize)
                                  .join(' ')}
                              </p>
                            )}
                        </div>

                        <div className="ml-auto text-[1.6rem] font-semibold text-black">
                          {formatCurrency(option.total_amount)}
                        </div>
                      </div>
                    </button>
                  );
                })}
            </div>
            <SheetClose ref={closeRef} />
          </SheetContent>
        </Sheet>
      )}
      {ratesLoading && (
        <div className="flex h-[6rem] w-full gap-4 rounded-[1rem]">
          <span className="flex h-[6rem] grow animate-pulse items-center justify-center rounded-[1rem] bg-gray-200 text-[1.8rem]">
            <span className="font-medium">Loading Shipping Rates...</span>
          </span>
        </div>
      )}
      {(ratesError as Error) && !ratesLoading && (
        <div className="text-center text-brand-red">
          {(ratesError as Error).message}
        </div>
      )}
      {rates === null && !ratesError && (
        <div>
          <h5 className=" text-[1.6rem] font-semibold">
            Error: No Shipping Available
          </h5>
          <p className="text-[1.4rem] text-brand-gray">
            Please contact the seller to add shipping to your country
          </p>
        </div>
      )}
    </div>
  );
}

export default ItemShippingSelect;
