import { z } from 'zod';

export const OfferFormSchema = z.object({
  price: z.number().gt(0),
  maxPrice: z.number().optional(),
  message: z.string().optional(),
  shippingAddress: z.string().min(1),
  paymentMethodId: z.string().min(1),
  termsAndConditions: z.boolean().refine((v) => v === true, {
    message: 'You must agree to the terms and conditions',
  }),
  rate: z
    .object({
      rate_id: z.string(),
      delivery_days: z.number().nullable(),
      total_amount: z.number(),
      carrier_delivery_days: z.string().nullable(),
      carrier_code: z.string(),
      service_type: z.string(),
    })
    .optional(),
  shippingCost: z.number(),
  tax: z.number(),
});

export type OfferSchemaType = z.infer<typeof OfferFormSchema>;

export type StepType =
  | 'offer-price'
  | 'shipping-payment'
  | 'shipping-options'
  | 'review-offer';
