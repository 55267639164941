import { ProductDocument, Variation } from 'models/product';
import BaseModal from '../../../components/modals/BaseModal';
import { MakeAnOfferForm } from '../forms/MakeAnOfferForm';

interface MakeAnOfferModalProps {
  isOpen: boolean;
  dismiss: (openDrawer?: boolean) => void;
  product: ProductDocument;
  bidInput?: string;
  selectedVariations: Variation[] | null;
}

const MakeAnOfferModal = ({
  isOpen,
  dismiss,
  product,
  selectedVariations,
}: MakeAnOfferModalProps) => {
  return (
    <BaseModal isOpen={isOpen} dismiss={dismiss} title={`Make An Offer`}>
      <MakeAnOfferForm
        product={product}
        selectedVariations={selectedVariations}
        dismiss={dismiss}
      />
    </BaseModal>
  );
};

export default MakeAnOfferModal;
