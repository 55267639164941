import { zodResolver } from '@hookform/resolvers/zod';
import { useStripe } from '@stripe/react-stripe-js';
import { useQuery } from '@tanstack/react-query';
import { getItem, logEvent } from '@util/analytics';
import { getNumber } from '@util/createListingHelpers';
import {
  createOffer,
  createOfferIntent,
  getOfferId,
  getOfferMessage,
} from '@util/firestore/offers';
import { getPaymentMethods } from '@util/firestore/payments';
import { getPublicUserDoc } from '@util/firestore/users';
import { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import { addressSchema } from 'models/address';
import { OfferDocument } from 'models/offer';
import { ProductDocument, Variation } from 'models/product';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getHostUrl } from '../../../utils';
import { OfferFormSchema, OfferSchemaType } from '../types';
import { getPricePercentageOff, validateProduct } from '../utils';

type useMakeAnOfferProps = {
  product: ProductDocument;
  dismiss: (openDrawer?: boolean) => void;
  selectedVariations?: Variation[] | null;
};

export const useMakeAnOffer = ({
  product,
  dismiss,
  selectedVariations,
}: useMakeAnOfferProps) => {
  const { userDoc } = useAuth();
  const router = useRouter();
  const stripe = useStripe();
  const { showErrorToast, showSuccessToast } = useToastContext();

  const useFormReturn = useForm<OfferSchemaType>({
    resolver: zodResolver(OfferFormSchema),
    defaultValues: {
      price: getPricePercentageOff({ product, percentage: 15 }),
    },
  });
  const { data: paymentMethodData } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: getPaymentMethods,
    enabled: !!userDoc,
  });
  const form = useFormReturn.watch();
  const onSubmit = async (data: OfferSchemaType) => {
    if (!userDoc || !stripe) return;
    const res = await validateProduct(product);
    if (!res.success) {
      useFormReturn.setError('root', {
        type: 'manual',
        message: res.message,
      });
      return;
    }
    const currProduct = res.product;
    const shippingAddressObj = JSON.parse(data.shippingAddress);
    shippingAddressObj.address_line2 = shippingAddressObj.address_line2 ?? '';
    const shippingAddress = addressSchema.parse(shippingAddressObj);

    const paymentMethod = paymentMethodData?.find(
      (paymentMethod) => paymentMethod.id === data.paymentMethodId
    );

    if (!paymentMethod?.id) {
      useFormReturn.setError('root', {
        type: 'manual',
        message: `Please select a payment method`,
      });
      return;
    }
    const seller = await getPublicUserDoc({
      uid: currProduct.seller_id,
      noCache: true,
    });
    if (!seller) {
      useFormReturn.setError('root', {
        type: 'manual',
        message: `Seller not found`,
      });
      return;
    }

    if (!currProduct.is_flat_rate && !form.rate?.rate_id) {
      useFormReturn.setError(
        'root',
        {
          type: 'manual',
          message: `Please select a shipment option`,
        },
        { shouldFocus: true }
      );
      return;
    }

    const now = Date.now();

    const letterVariation = selectedVariations?.find(
      (v) => !v.is_number && !v.color
    );
    const numberVariation = selectedVariations?.find(
      (v) => v.is_number && !v.color
    );
    const colorVariation = selectedVariations?.find((v) => v.color);

    const timeRemaining = now + 172_800_000;

    // review shipping before creating offer
    const offer: OfferDocument = {
      id: getOfferId(),
      is_counter: false,
      is_exclusive: false,
      attribution: {},
      buyer_id: userDoc.uid,
      seller_id: seller.uid,
      rate_id: data.rate?.rate_id ?? '',
      product_id: currProduct.id,
      current_price: currProduct.price,
      account_id: '', // added in backend
      customer_id: userDoc.customer_id,
      is_auction: false,
      price: getNumber(data.price),
      shipping_cost: data.shippingCost,
      total: data.shippingCost + getNumber(data.price) + data.tax,
      message: '',
      address: shippingAddress,
      payment: paymentMethod,
      uids: [userDoc.uid, seller.uid],
      created: now,
      time_remaining: timeRemaining,
      from_web: true,
      state: 0,
      tax: data.tax,
      ...(selectedVariations && {
        variation: {
          ...(!!letterVariation && {
            letter: letterVariation.size as string,
          }),
          ...(!!numberVariation && {
            number: +numberVariation.size as number,
          }),
          ...(!!colorVariation && {
            color: colorVariation.color as string,
          }),
        },
      }),
    };

    offer.message = getOfferMessage(
      currProduct.title,
      offer.price,
      offer.shipping_cost,
      offer.tax,
      offer.message,
      selectedVariations,
      currProduct.size,
      offer.address
    );

    const offerIntent = await createOfferIntent(offer.price, offer.customer_id);
    const return_url = `${getHostUrl()}/dashboard/offers?retry=true`;
    const { error } = await stripe.confirmCardSetup(offerIntent.data, {
      payment_method: paymentMethod.id,
      return_url,
    });

    if (error) {
      useFormReturn.setError(
        'root',
        {
          message:
            'Error confirming payment method. Please refresh the page and try again.',
        },
        { shouldFocus: true }
      );
      showErrorToast(
        'Error confirming payment method. Please refresh the page and try again.'
      );
      return;
    } else {
      await createOffer(offer);
      logEvent(
        'created_offer',
        {
          items: [getItem(currProduct)],
          offer_id: offer.id,
        },
        userDoc.uid
      );
      dismiss();
      showSuccessToast('Offer created successfully');
      router.push(`/dashboard/offers?offerId=${offer.id}`);
    }
    return;
  };

  return {
    useFormReturn,
    getPricePercentageOff,
    onSubmit,
  };
};
