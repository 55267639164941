import { ProductDocument } from 'models/product';
import { OfferSchemaType } from '../types';
import { getProductById } from '@util/firestore/products';
import { logEvent } from '@util/analytics';
import { calculateShipping, getShippingRegion } from '@util/firestore/cart';
import { AddressDocument, addressSchema } from 'models/address';
import { getItem } from '@util/analytics/analytics.service';
import { getNumber } from '@util/createListingHelpers';
import { getTax } from '@util/firestore/taxjar';

export const validatePrice = ({
  form,
  product,
}: {
  form: OfferSchemaType;
  product: ProductDocument;
}): string[] => {
  const errors: string[] = [];
  const priceNumber = form.price;
  if (!priceNumber) {
    const error = `Please enter a valid price`;
    errors.push(error);
  }

  if (priceNumber < (product.minimum_offer_price ?? 0)) {
    const error = `Your offer is lower than the buyers minimum offer price. Please try again.`;

    errors.push(error);
  }

  if (priceNumber > product.price) {
    const error = `Your offer is higher than the buyers asking price. Did you mean to buy now?`;
    errors.push(error);
  }

  return errors;
};

export const validateTermsAndConditions = (form: OfferSchemaType) => {
  if (!form.termsAndConditions) {
    return ['Please acknowledge that this offer is binding.'];
  }
  return [];
};

export const validateShippingAddress = (form: OfferSchemaType) => {
  if (!form.shippingAddress) {
    return ['Please enter a valid shipping address.'];
  }
  return [];
};

export const validatePaymentMethod = (form: OfferSchemaType) => {
  if (!form.paymentMethodId) {
    return ['Please select a payment method.'];
  }
  return [];
};

export const validateProduct = async (
  product: ProductDocument
): Promise<
  | { success: true; product: ProductDocument }
  | { success: false; message: string }
> => {
  const currProduct = await getProductById(product.id);
  if (!currProduct || currProduct.out_of_stock) {
    return {
      success: false,
      message: 'This product no longer in stock.',
    };
  }
  return {
    success: true,
    product: currProduct,
  };
};

export const validateShippingOptions = ({
  form,
  product,
}: {
  form: OfferSchemaType;
  product: ProductDocument;
}) => {
  if (!product.is_flat_rate && !form.rate?.rate_id) {
    return ['Please select a shipping rate'];
  }
  return [];
};

export const calcShipping = ({
  form,
  product,
}: {
  form: OfferSchemaType;
  product: ProductDocument;
}) => {
  if (!form.shippingAddress) {
    throw new Error('No shipping address selected');
  }
  if (product.is_flat_rate) {
    const shippingAddress = JSON.parse(form.shippingAddress) as AddressDocument;

    const shipping = calculateShipping(
      product,
      getShippingRegion(shippingAddress)
    );

    if (!shipping) {
      throw new Error(
        'No shipping options available for this product to this address'
      );
    }

    logEvent('add_shipping_info', {
      currency: 'USD',
      items: [getItem(product)],
      shipping_tier: form.rate?.service_type ?? 'flat_rate',
    });

    return shipping.cost;
  } else {
    if (!form.rate?.rate_id) {
      throw new Error('No shipping rate selected');
    }
    return form.rate?.total_amount ?? 0;
  }
};

export function getPricePercentageOff({
  product,
  percentage,
}: {
  product: ProductDocument;
  percentage: number;
}) {
  const price = getNumber(product.price);

  return Number((price * (1 - percentage / 100)).toFixed(2));
}
